import {graphql, PageProps} from "gatsby";
import React from "react";
import {createNavItems} from "../utils/nav-items-util";
import PageWrapper from "../components/PageWrapper";
import Layout from "../components/Layout";
import SliceZone from "./slices/SliceZone";
import SEO from "../components/seo";

type Props = {
  allPrismicProjects: {
    nodes: {
      uid: string
      url: string
      tags: string[]
      first_publication_date: string
      last_publication_date: string
      data: {
        name: {
          text: string
        }
        description: {
          text: string
        }
        app_icon: {
          url: string
        }
        google_play: {
          url: string
        }
        app_store: {
          url: string
        }
        cover: {
          url: string
        }
        video: {
          url: string
        }
        body: {
          slice_type: string
        }[]
      }
    }[]
  }
}

const ProjectPost: React.FC<PageProps<Props>> = ({data: {allPrismicProjects}, location}) => {
  const node = allPrismicProjects.nodes[0];
  const project = node.data;
  return (
    <Layout navItems={createNavItems({current: "/"})}>
      <SEO
        title={project.name.text}
        description={project.description.text}
        pathname={location.pathname}
        image={project.cover?.url}
      />
      <PageWrapper className={"flex flex-col items-center mt-8 mb-16"}>
        <div className="flex flex-wrap items-center mx-auto md:mx-36">
          <div className="w-full md:w-1/3">
            <img className="mx-auto" src={project.app_icon.url} alt={project.name.text} width="64"/>
          </div>
          <div className="w-full md:w-2/3 prose text-center md:text-left">
            <h2>{project.name.text}</h2>
          </div>
        </div>
        <div className="my-8 text-center prose">{project.description.text}</div>
        <div>
          <img className="mx-auto" src={project.cover.url} alt={project.name.text}/>
        </div>
        <div className="w-full flex justify-center mx-auto">          
          {project.google_play.url &&
          <a href={project.google_play.url} target="_blank" rel="noopener">
            <img className="mx-auto h-16 object-contain" src="/images/google-play-badge.png"
                 alt="Download from Google Play" width="165" height="164"/>
          </a>}
          {project.app_store.url &&
          <a href={project.app_store.url} target="_blank" rel="noopener">
            <img className="mx-auto h-16 object-contain" src="/images/app-store-badge.png"  width="130" height="64"
                 alt="Download from App Store"/>
          </a>}          
        </div>
        <div className="prose">
          <SliceZone slices={project.body as any[]}/>
        </div>
      </PageWrapper>
    </Layout>
  );
}

export default ProjectPost;

export const query = graphql`
  query ProjectByUid($uid: String!) {
    allPrismicProjects(filter: {uid: {eq: $uid}}) {
      nodes {
        uid
        url
        tags
        first_publication_date
        last_publication_date
        data {
          name {
            text
          }
          description {
            text
          }
          app_icon {
            url
          }
          google_play {
            url
          }
          app_store {
            url
          }
          cover {
            url
            fluid {
              src
            }
          }
          video {
            url
          }
          body {
              __typename
              ... on PrismicProjectsBodyQuote {
                id
                slice_type
                primary {
                  quote {
                    html
                  }
                }              
              }
              ... on PrismicProjectsBodyCodeBlock {
                id
                slice_type
                primary {
                  code_block {
                    html
                  }
                }
                slice_label
              }
              ... on PrismicProjectsBodyImageGallery {
                id
                slice_type
                items {
                  image_captions {
                    text
                  }
                  gallery_image {
                    alt
                    url
                  }
                }
                primary {
                  name_of_the_gallery{
                    html
                  }
                }
              }
              ... on PrismicProjectsBodyText {
                id
                slice_type
                primary {
                  text {
                    html
                  }
                }
              }
              ... on PrismicProjectsBodySingleImage {
                id
                slice_type
                primary {
                  image {
                    url
                    alt
                  }
                }              
              }
            }
        }
      }
    }
  }
`
